import React, { FC, useMemo, useState } from 'react';
import { Grid as DeprecatedGrid } from 'wix-ui-tpa';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useWidgetViewModel } from '../hooks/useWidgetViewModel';
import { ServiceCard } from './ServiceCard/ServiceCard';
import { DataHooks, DEFAULT_IMAGE_CONTAINER, SIDE_PADDING } from './consts';
import { VerticalAlignmentProvider } from '../hooks/useVerticalAlignment';
import { classes } from './Body.st.css';
import {
  generateServiceInfoDetails,
  getDetailsAlignment,
} from '../../../../utils/serviceDetails/displayOptions';
import { useServiceInfoLayout } from '../hooks/useServiceInfoLayout';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import { Grid } from '../Grid';

export interface BodyProps {
  widgetId: string;
}

export const Body: FC<BodyProps> = ({ widgetId }) => {
  const { services, bodyViewModel, businessInfo, coursesAvailability } =
    useWidgetViewModel();
  const serviceInfoLayout = useServiceInfoLayout();
  const settings = useSettings();
  const { t } = useTranslation();
  const { language } = useEnvironment();
  const { experiments } = useExperiments();
  const isImageKitEnabled = experiments.enabled('specs.bookings.ImageKit');
  const isGridMediaQueriesFixEnabled = experiments.enabled(
    'specs.bookings.gridMediaQueriesFix',
  );
  const isServiceDividerVisible = settings.get(
    settingsParams.isServiceDividerVisible,
  );
  const isTagLineVisible = settings.get(settingsParams.isTagLineVisible);
  const isServiceOfferedDaysVisible = settings.get(
    settingsParams.isServiceOfferedDaysVisible,
  );
  const isServiceStartDateVisible = settings.get(
    settingsParams.isServiceStartDateVisible,
  );
  const isServiceDurationVisible = settings.get(
    settingsParams.isServiceDurationVisible,
  );
  const isServicePriceVisible = settings.get(
    settingsParams.isServicePriceVisible,
  );
  const detailsAlignment = useMemo(() => {
    const allServiceInfoDetails = services.map((service) =>
      generateServiceInfoDetails({
        service,
        displayOptions: {
          isServiceDurationVisible,
          isServiceOfferedDaysVisible,
          isServicePriceVisible,
          isServiceStartDateVisible,
          isTagLineVisible,
        },
        t,
        language,
        businessInfo,
        coursesAvailability,
      }),
    );
    return getDetailsAlignment({
      allServiceInfoDetails,
      serviceInfoLayout,
      isServiceDividerVisible,
    });
  }, [
    services,
    serviceInfoLayout,
    isServiceDividerVisible,
    isServiceDurationVisible,
    isServicePriceVisible,
    isServiceStartDateVisible,
    isServiceOfferedDaysVisible,
    isTagLineVisible,
  ]);
  const { isMobile, dimensions } = useEnvironment();
  const [{ width, height }, setBoundingClientRect] = useState(
    DEFAULT_IMAGE_CONTAINER,
  );
  const {
    spaceBetweenCards,
    maxCardsPerRow,
    cardMinWidth,
    cardMaxWidth,
    showDivider,
    dividerWidth,
    imageRatio,
  } = bodyViewModel;

  return (
    <div
      className={classes.root}
      data-hook={DataHooks.ROOT}
      style={
        {
          '--image-aspect-ratio': imageRatio,
        } as React.CSSProperties
      }
    >
      <VerticalAlignmentProvider value={detailsAlignment}>
        {isGridMediaQueriesFixEnabled ? (
          <Grid
            id={`grid-${widgetId}`}
            className={classes.grid}
            width={dimensions.width ? dimensions.width - SIDE_PADDING * 2 : 0}
            rowGap={spaceBetweenCards}
            columnGap={spaceBetweenCards}
            maxColumns={maxCardsPerRow}
            minColumnWidth={cardMinWidth}
            maxColumnWidth={cardMaxWidth}
            showRowDivider={showDivider}
            dividerWidth={dividerWidth}
            uniformRowHeight={!isMobile}
            isMobile={isMobile}
            isGridMediaQueriesFixEnabled={isGridMediaQueriesFixEnabled}
          >
            {services.map((service, index) => (
              <Grid.Item key={`${service.id}-${index}`}>
                <ServiceCard
                  service={service}
                  setBoundingClientRect={
                    index === 0 && !isImageKitEnabled
                      ? setBoundingClientRect
                      : undefined
                  }
                  width={width}
                  height={height}
                />
              </Grid.Item>
            ))}
          </Grid>
        ) : (
          <DeprecatedGrid
            id={`grid-${widgetId}`}
            className={classes.deprecatedGrid}
            data-hook={DataHooks.DEPRECATED_GRID}
            width={dimensions.width ? dimensions.width - SIDE_PADDING * 2 : 0}
            rowGap={spaceBetweenCards}
            columnGap={spaceBetweenCards}
            maxColumns={maxCardsPerRow}
            minColumnWidth={cardMinWidth}
            maxColumnWidth={cardMaxWidth}
            showRowDivider={showDivider}
            dividerWidth={dividerWidth}
            uniformRowHeight={!isMobile}
          >
            {services.map((service, index) => (
              <DeprecatedGrid.Item key={`${service.id}-${index}`}>
                <ServiceCard
                  service={service}
                  setBoundingClientRect={
                    index === 0 && !isImageKitEnabled
                      ? setBoundingClientRect
                      : undefined
                  }
                  width={width}
                  height={height}
                />
              </DeprecatedGrid.Item>
            ))}
          </DeprecatedGrid>
        )}
      </VerticalAlignmentProvider>
    </div>
  );
};
